import { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { Dashboard } from '../pages'
import {
  Celcoin,
  Fees,
  LeftSide,
  Limits,
  Login,
  Order,
  OrderDetails,
  Payments,
  Pix,
  PixCelcoin,
  Statement,
  StatementDetails,
  TwoFactor,
  Wallets,
  Webhook,
  WebhookDetails
} from '../shared/components'
import { useAuthContext, useDrawerContext } from '../shared/contexts'

export const AppRoutes = () => {
  const { setDrawerOptions } = useDrawerContext()
  const { isAuthenticated } = useAuthContext()

  useEffect(() => {
    setDrawerOptions([
      {
        icon: 'home',
        path: '/index',
        label: 'Inicio'
      },
      {
        icon: 'receipt',
        path: '/statement',
        label: 'Extratos'
      },
      {
        icon: 'receipt_long',
        path: '/orders',
        label: 'Ordens'
      },
      {
        icon: 'webhook',
        path: '/webhooks',
        label: 'Webhooks'
      },
      // {
      //   icon: 'payments',
      //   path: '/payments',
      //   label: 'Sacar'
      // },
      {
        icon: 'percent',
        path: '/fees',
        label: 'Custos'
      },
      {
        icon: 'assessment',
        path: '/limits',
        label: 'Segurança'
      },
      {
        icon: 'wallet',
        path: '/wallets',
        label: 'Carteiras'
      },
      {
        icon: 'account_balance',
        path: '/celcoin',
        label: 'Bound'
      },
      {
        icon: 'phonelink_lock',
        path: '/two-factor',
        label: 'Autenticação 2FA'
      }
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isAuthenticated) {
    return (
      <LeftSide>
        <Routes>
          <Route path="*" element={<Navigate to="/index" />} />

          <Route path="/index" element={<Dashboard />} />
          <Route path="/orders" element={<Order />} />
          <Route path="/orders/:IdDetails" element={<OrderDetails />} />
          <Route path="/webhooks" element={<Webhook />} />
          <Route path="/statement" element={<Statement />} />
          <Route path="/statement/:IdDetails" element={<StatementDetails />} />
          <Route path="/webhooks/:IdDetails" element={<WebhookDetails />} />
          <Route path="/pix" element={<Pix />} />
          <Route path="/pix-celcoin" element={<PixCelcoin />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/fees" element={<Fees />} />
          <Route path="/limits" element={<Limits />} />
          <Route path="/wallets" element={<Wallets />} />
          <Route path="/celcoin" element={<Celcoin />} />
          <Route path="/two-factor" element={<TwoFactor />} />
        </Routes>
      </LeftSide>
    )
  } else {
    return (
      <Routes>
        <Route path="*" element={<Navigate to="/login" />} />

        <Route path="/login" element={<Login />} />
      </Routes>
    )
  }
}
