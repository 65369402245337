import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'

import { IWallet } from '../../interfaces/wallets'
import { WalletsService } from '../../services/api/wallets/WalletsService'

interface IDialog {
  isOpen: boolean
  onClose: () => void
  updateWallet: IWallet | null
  reloadWallets: () => void
}

export const ModalUpdate: React.FC<IDialog> = ({
  isOpen,
  onClose,
  updateWallet,
  reloadWallets
}) => {
  const [blockedBalance, setBlockedBalance] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleBlockedBalanceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rawValue = event.target.value
    // Certifique-se de que cost é sempre uma string
    const numericValue = rawValue.replace(/[^0-9]/g, '')
    // Formata o valor para o formato de real brasileiro (R$)
    const formattedValue = numericValue.replace(/(\d{1,})(\d{2})$/, '$1,$2')

    setBlockedBalance(formattedValue)
  }

  const handleOnClose = () => {
    onClose()
  }

  const handleSubmit = () => {
    if (!updateWallet) return

    setIsLoading(true)
    const payload = {
      wallet: {
        blockedBalance: Number(blockedBalance.replace(',', '.'))
      }
    }

    WalletsService.update(updateWallet.id, payload).then((result) => {
      setIsLoading(false)
      if (result.status === 200) {
        toast.success('Carteira atualizada.')
        reloadWallets()
        handleOnClose()
      } else {
        toast.error(result)
      }
    })
  }

  useEffect(() => {
    if (updateWallet) {
      setBlockedBalance(updateWallet.blockedBalance?.toString() || '')
    }
  }, [updateWallet])

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          Editar carteira
          <IconButton onClick={handleOnClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 2
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <TextField
              fullWidth
              label="Saldo Devedor"
              name="blockedBalance"
              value={blockedBalance?.replace('.', ',')}
              onChange={handleBlockedBalanceChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography>R$</Typography>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Button
            variant="contained"
            disabled={isLoading}
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            ATUALIZAR
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
