import {
  IWallet,
  IWalletsIndexResponse,
  IWalletUpdate
} from '../../../interfaces/wallets'
import { Api } from '../axios-config'

const index = async () => {
  const response = await Api.get<IWalletsIndexResponse>('/wallets')

  return response
}

const update = async (id: number, payload: IWalletUpdate) => {
  const response = await Api.patch<IWallet>(`/wallets/${id}`, payload)
    .then((result) => {
      return result
    })
    .catch((error) => {
      const { data } = error.response as {
        data: Record<string, string[]>
      }

      if (data) {
        const errors = Object.keys(data).map((key) => {
          const propertyName =
            key === 'blockedBalance' ? 'Saldo bloqueado' : key
          return `${propertyName}: ${data[key].join(', ')}`
        })

        return errors.join('\n')
      }
      return error
    })

  return response
}

export const WalletsService = {
  index,
  update
}
