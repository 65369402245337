import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  Breadcrumbs,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { IWallet } from '../../interfaces/wallets'
import { LayoutBase } from '../../layouts'
import { WalletsService } from '../../services/api/wallets/WalletsService'
import { maskDateHour, maskReaisNumber } from '../../utils/Format'
import { ModalUpdate } from './ModalUpdate'

export const Wallets: React.FC = () => {
  const [wallets, setWallets] = useState<IWallet[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [walletForUpdate, setWalletForUpdate] = useState<IWallet | null>(null)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const fetchWallets = async () => {
    setIsLoading(true)
    try {
      const response = await WalletsService.index()
      setWallets(response.data.wallets)
      setTotalCount(response.data.totalCount)
    } catch (error) {
      toast.error('Erro ao buscar carteiras.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdate = (item: IWallet) => {
    setWalletForUpdate(item)
    setIsOpenUpdate(true)
  }

  const handleOnClose = () => {
    setIsOpenUpdate(false)
    setWalletForUpdate(null)
  }

  useEffect(() => {
    fetchWallets()
  }, [])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>wallet</Icon>
          <Typography color="text.primary">Carteiras</Typography>
        </Breadcrumbs>
      </Box>

      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}

      {!isLoading && wallets && wallets.length === 0 && (
        <Box sx={{ m: 2 }}>
          <Typography variant="caption">Nenhum registro encontrado.</Typography>
        </Box>
      )}

      {!isLoading && wallets && wallets.length > 0 && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Criado em</TableCell>
                <TableCell>Saldo</TableCell>
                <TableCell>Saldo pendente</TableCell>
                <TableCell>Saldo devedor</TableCell>
                <TableCell>Ultima atualização</TableCell>
                <TableCell align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wallets.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{maskDateHour(item.createdAt)}</TableCell>
                  <TableCell>{maskReaisNumber(item.balance)}</TableCell>
                  <TableCell>{maskReaisNumber(item.pendingBalance)}</TableCell>
                  <TableCell>{maskReaisNumber(item.blockedBalance)}</TableCell>
                  <TableCell>{maskDateHour(item.updatedAt)}</TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleUpdate(item)}>
                      <Icon>edit</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter></TableFooter>
          </Table>
        </TableContainer>
      )}

      {totalCount > 0 && totalCount > perPage && !isLoading && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '95%' }}
          p={2}
        >
          <Pagination
            page={page}
            count={Math.ceil(totalCount / perPage)}
            onChange={(_, newPage) => setPage(newPage)}
            siblingCount={5}
            boundaryCount={10}
          />
          <FormControl>
            <InputLabel id="label-perpage">Por página</InputLabel>
            <Select
              labelId="label-perpage"
              value={perPage}
              label="Por página"
              onChange={(e) => setPerPage(Number(e.target.value))}
              sx={{ width: '100px' }}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      <ModalUpdate
        isOpen={isOpenUpdate}
        onClose={handleOnClose}
        updateWallet={walletForUpdate}
        reloadWallets={fetchWallets}
      />
    </LayoutBase>
  )
}
