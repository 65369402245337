import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Grid,
  Icon,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { IOrder } from '../../../interfaces'
import { LayoutBase } from '../../../layouts'
import { PanelService } from '../../../services/api/panel/PanelServices'
import { maskDateHour, maskReaisNumber } from '../../../utils/Format'

export const OrderDetails: React.FC = () => {
  const [order, setOrder] = useState<IOrder>()
  const [isLoading, setIsLoading] = useState(false)

  const { IdDetails } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    if (!IdDetails) {
      toast.error('Ordem não encontrada')
      navigate('/orders')
      setIsLoading(false)
    } else {
      PanelService.getOrder(Number(IdDetails)).then((result) => {
        setIsLoading(false)
        if (result.response.status === 200) {
          setOrder(result.data)
        } else {
          toast.error('Ordem não encontrada')
          navigate('/orders')
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LayoutBase>
      <Box sx={{ m: 2 }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator="|"
          sx={{ display: 'flex', justifyContent: 'start' }}
        >
          <Icon>receipt_long</Icon>
          <Typography color="text.primary">Ordens</Typography>
          <Typography color="text.primary">Detalhes da Ordem</Typography>
        </Breadcrumbs>
      </Box>
      {isLoading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="primary" />
        </Box>
      )}
      {!isLoading && order && (
        <>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            display="flex"
            justifyContent="center"
            padding={2}
            gap={2}
          >
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Typography variant="h5" textAlign="center">
                Ordem {order.id}
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Typography variant="subtitle2" textAlign="center">
                  Criado em
                </Typography>
                <Typography variant="body2" textAlign="center">
                  {maskDateHour(order.createdAt)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Typography variant="subtitle2" textAlign="center">
                  ID da Ordem
                </Typography>
                <Typography variant="body2" textAlign="center">
                  {order.orderId}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Typography variant="subtitle2" textAlign="center">
                  Status
                </Typography>
                <Typography variant="body2" textAlign="center">
                  {order.status === 'paid' && 'Pago'}
                  {order.status === 'pending' && 'Pendente'}
                  {order.status === 'cancelled' && 'Cancelado'}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Typography variant="subtitle2" textAlign="center">
                  Atualizado em
                </Typography>
                <Typography variant="body2" textAlign="center">
                  {maskDateHour(order.updatedAt)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Typography variant="subtitle2" textAlign="center">
                  Tipo de Ordem
                </Typography>
                <Typography variant="body2" textAlign="center">
                  {order.orderType}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Typography variant="subtitle2" textAlign="center">
                  Usuário
                </Typography>
                <Typography variant="body2" textAlign="center">
                  {order.userId}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Typography variant="subtitle2" textAlign="center">
                  Valor
                </Typography>
                <Typography variant="body2" textAlign="center">
                  {maskReaisNumber(order.value)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                <Typography variant="subtitle2" textAlign="center">
                  Motivo de Cancelamento
                </Typography>
                <Typography variant="body2" textAlign="center">
                  {order.cancelledReason}
                </Typography>
              </Grid>
            </Grid>

            {order.orderType === 'pix cashout' ||
              (order.orderType === 'pix cashout celcoin' && (
                <>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Typography variant="h5" textAlign="center">
                      Webhook
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Código do Cliente
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.ClientCode}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        End to End
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.EndToEndId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Tipo de Pagamento
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.PaymentType}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Descrição
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.StatusCode?.Description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        ID do Status
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.StatusCode?.StatusId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Locatário
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.Tenant}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        ID da Transação
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.TransactionId}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Tipo de Transação
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.TransactionType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Tipo de Transação Pix
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.TransactionTypePix}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                    <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        Urgência
                      </Typography>
                      <Typography variant="body2" textAlign="center">
                        {order.webhookReceived?.Urgency}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              ))}

            {order.orderType === 'pix cashin' && order.status === 'paid' && (
              <>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Typography variant="h5" textAlign="center">
                    Webhook
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Tipo de Transação
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.TransactionType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      ID da Transação
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.TransactionId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Valor
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {maskReaisNumber(order.webhookReceived?.Amount)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      End to End
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.EndToEndId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Identificação da Transação
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.TransactionIdentification}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      ID da Transação BrCode
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.TransactionIdBRCode}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Typography variant="h6" textAlign="center">
                    Origem
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Banco
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.DebitParty?.Bank}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Agência
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.DebitParty?.Branch}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Conta
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.DebitParty?.Account}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Tipo
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.DebitParty?.PersonType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      TaxID
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.DebitParty?.TaxId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Tipo de Conta
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.DebitParty?.AccountType}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Nome
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.DebitParty?.Name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Typography variant="h6" textAlign="center">
                    Destino
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Banco
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.CreditParty?.Bank}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Agência
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.CreditParty?.Branch}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Conta
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.CreditParty?.Account}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Tipo
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.CreditParty?.PersonType}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      TaxID
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.CreditParty?.TaxId}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Tipo de Conta
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.CreditParty?.AccountType}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} xl={12} lg={12}>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Nome
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.CreditParty?.Name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} xl={4} lg={4}>
                    <Typography variant="subtitle2" textAlign="center">
                      Chave
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      {order.webhookReceived?.CreditParty?.Key}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            display="flex"
            justifyContent="center"
            padding={2}
            gap={2}
          >
            <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
              <Typography variant="h5" textAlign="center" mb={2}>
                Auditória
              </Typography>
              {order.versions?.length === 0 && (
                <Typography variant="body2" textAlign="center">
                  Nenhuma alteração feita
                </Typography>
              )}
              {order.versions &&
                order.versions?.map((version, index) => (
                  <Accordion
                    key={index}
                    slotProps={{ transition: { unmountOnExit: true } }}
                  >
                    <AccordionSummary
                      expandIcon={<Icon>expand_more</Icon>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ fontWeight: 'bold' }}
                    >
                      {`Versão ${index + 1} - ${maskDateHour(
                        version.createdAt
                      )} - Alterações feitas por: ${
                        version.whodunnit
                      } usando ip: ${version.remoteIp} e user agent: ${
                        version.userAgent
                      } - Evento: ${version.event} - Request ID: ${
                        version.requestId
                      }`}
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer key={index}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>ATRIBUTO</TableCell>
                              <TableCell>ANTES</TableCell>
                              <TableCell>DEPOIS</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(version.objectChanges).map(
                              (key, index) => {
                                const values = version.objectChanges[key]
                                const before =
                                  values[0] === null ? '-' : values[0]
                                const after =
                                  values[1] === null ? '-' : values[1]
                                const displayBefore =
                                  typeof before === 'object'
                                    ? JSON.stringify(before)
                                    : before
                                const displayAfter =
                                  typeof after === 'object'
                                    ? JSON.stringify(after)
                                    : after
                                return (
                                  <TableRow key={index}>
                                    <TableCell>{key}</TableCell>
                                    <TableCell>
                                      {key === 'updatedAt'
                                        ? maskDateHour(displayBefore)
                                        : displayBefore}
                                    </TableCell>
                                    <TableCell>
                                      {key === 'updatedAt'
                                        ? maskDateHour(displayAfter)
                                        : displayAfter}
                                    </TableCell>
                                  </TableRow>
                                )
                              }
                            )}
                            <TableRow key="url">
                              <TableCell>url</TableCell>
                              <TableCell>{version.originalUrl}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Grid>
          </Grid>
        </>
      )}
    </LayoutBase>
  )
}
